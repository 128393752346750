import React, { useState, useEffect } from "react";
import axios from "../../axios";

import { Link } from "react-router-dom";

import { CSSTransition } from "react-transition-group";

import Language from "../Language/Language";

import languageData from '../variables/language.json';

import "./News.css";

function News(props) {
  const [articles, setArticles] = useState([]);
  const [title, setTitle] = useState([]);
  const [lang, setLang] = useState("");

  useEffect(() => {
    setLang(props.lang);
  }, [props.lang])

  useEffect(() => {
    if(lang !== "") {
      document.title = "EuroSouvenir | " + languageData.news[lang];
      getArticles();
    }
  }, [lang]);

  const getArticles = async () => {
    const req = await axios.get("/eurosouvenir/articles/get", {
      params: {
        lang,
      }
    });
    setArticles(req.data);

    let news = languageData.news[lang];
    let arrayOfTitle = [];
      for (let i = 0; i < news.length; i++) {
        if (i === 0)
          arrayOfTitle.push(
            <span key={i} style={{ transitionDelay: "350ms" }}>
              {news[i]}
            </span>
          );
        else
          arrayOfTitle.push(
            <span key={i} style={{ transitionDelay: (i + 2) * 175 + "ms" }}>
              {news[i]}
            </span>
          );
      }
      setTitle(arrayOfTitle);
  };

  return (
    <div className="content">
      <div className="content--inner">
        <div className="itemContainer news">
        <Language contact={false} />
          <div className="titleWrapper news">
            <h2>
              <span className="hiddenOpacity">
                <span className="titleDark">Euro</span>Souvenir{" "}
              </span>
              <span className="titleLine">|</span>{" "}
              <span
                className="titleSmall hungaryEntryAnimation"
                id="newsEntryAnimation"
              >
                <CSSTransition timeout={500} in={articles.length > 0 ? true : false} unmountOnExit={true} classNames="show">
                  <span>
                    {title}
                  </span>
                </CSSTransition>
              </span>
            </h2>
          </div>

          <div className="articlesWrapper">
            <div className="articlesContainer">
              {articles.map((article, index) => (
                <div className="articleWrapper" key={article._id}>
                  <div className="articleContainer">
                    <div>
                      <div className="articleTitle">{article.title[lang]}</div>
                      <div className="articleDesc">
                        {article.shortDescription[lang]}
                      </div>
                    </div>
                    <div>
                      <div className="articleImg">
                        <img src={article.articleImgURL} alt="" />
                      </div>
                      <div className="showArticle">
                        <Link to={`/article/${article._id}`}>
                          <button>{languageData.showArticle[lang]}</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
