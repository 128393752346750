import axios from "../../axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import {
  GET_ERRORS,
  USER_LOADING,
  SET_CURRENT_USER,
  POINT_DELETED,
  ARTICLE_DELETED
} from "./types";

export const registerUser = (userData, history) => (dispatch) => {
  axios
    .post("/eurosouvenir/users/register", userData)
    .then((res) => history.push("/login"))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const deleteArticle = (user, id) => (dispatch) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/eurosouvenir/articles/delete", { _id: user, article: id })
      .then(() => {
          resolve(true);
          dispatch(articleDeleted);
      })
      .catch((err) => {
        reject(err);
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      });
  });
};

export const deleteMapPoint = (user, id) => (dispatch) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/eurosouvenir/points/delete", { _id: user, point: id })
      .then(() => {
          resolve(true);
          dispatch(pointDeleted);
      })
      .catch((err) => {
        reject(err);
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      });
  });
};

export const loginUser = (userData) => (dispatch) => {
  axios
    .post("/eurosouvenir/users/login", userData)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const articleDeleted = () => {
  return {
    type: ARTICLE_DELETED,
  };
};


export const pointDeleted = () => {
  return {
    type: POINT_DELETED,
  };
};

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const userLoading = () => {
  return {
    type: USER_LOADING,
  };
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("jwtToken");
  setAuthToken(false);
  dispatch(setCurrentUser({}));
};
