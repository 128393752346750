import React, { useState, useEffect } from "react";
import "./Admin.css";

import { logoutUser, deleteArticle } from "../actions/authActions";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";
import axios from "../../axios";

import * as FormData from "form-data";

import { Link } from "react-router-dom";

import cryptoRandomString from "crypto-random-string";

import { Editor } from "@tinymce/tinymce-react";

import VideoPlayer from "../VideoPlayer/VideoPlayer";

import Resizer from "react-image-file-resizer";

import AdminMap from "./AdminMap";
import Point from "../Point/Point";

import CloseIcon from "@material-ui/icons/Close";
import Scrollbar from "../Scrollbar/Scrollbar";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { validateInput, validateArticleInput } from "./validateInput";

import { animateScroll } from "react-scroll";

function NewArticle(props) {
  const [errors, setErrors] = useState({});
  const [title, setTitle] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [articleBody, setArticleBody] = useState("");
  const [articleImg, setArticleImg] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const [editingArticle, setEditingArticle] = useState({});

  const [lang, setLang] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setErrors({});
      setTitle("");
      setShortDescription("");
      setArticleBody("");
      setArticleImg("");
      setSubmitted(false);
      setEditingArticle({});
    }, 350);
  }, [props.reset]);

  useEffect(() => {
    setLang(props.lang);
  }, [props.lang]);

  useEffect(() => {
    if (props.editArticle !== "") {
      if (lang !== "") getArticle(props.editArticle);
    }
  }, [props.editArticle, lang]);

  const getArticle = async (id) => {
    const req = await axios.get("/eurosouvenir/articles/single", {
      params: {
        _id: id,
        lang,
      },
    });
    setEditingArticle(req.data);
    setTitle(req.data.title[lang]);
    setShortDescription(req.data.shortDescription[lang]);
    setArticleBody(req.data.articleBody[lang]);
    setArticleImg(req.data.articleImgURL);
  };

  const submitNewArticle = async (e) => {
    e.preventDefault();

    const newArticleObject = {
      title,
      shortDescription,
      articleBody,
      articleImg,
    };

    const validInput = validateArticleInput(newArticleObject);

    if (validInput.isValid === true) {
      setSubmitted(true);
      document.getElementById("uploadingNewArticle").classList.add("visible");

      if (
        Object.keys(editingArticle).length > 0 &&
        editingArticle.constructor === Object
      ) {
        axios
          .post("/eurosouvenir/articles/edit", {
            _id: editingArticle._id,
            editingArticle,
            title,
            shortDescription,
            articleBody,
            articleImg,
            lang,
          })
          .then(() => {
            document
              .getElementById("uploadingNewArticle")
              .classList.remove("visible");
            props.close();
          });
      } else {
        axios
          .post("/eurosouvenir/articles/new", {
            title,
            shortDescription,
            articleBody,
            articleImg,
          })
          .then(() => {
            document
              .getElementById("uploadingNewArticle")
              .classList.remove("visible");
            props.close();
          });
      }
    } else {
      setErrors(validInput.errors);
    }
  };

  const handleEditorChange = (e) => {
    setArticleBody(e);
  };

  const handleArticleImg = async (e) => {
    const image = await resizeFile(e.target.files[0]);
    setArticleImg(image);
  };

  const deleteArticleImg = () => {
    setArticleImg("");
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        "PNG",
        1000,
        1000,
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  return (
    <div className="newArticleComponent">
      <div className="backButton" onClick={props.closeNewArticle}>
        <ArrowBackIcon />
      </div>
      <div className="newArticleContainer">
        <div className="newArticleFormWrapper">
          {props.editArticle !== "" ? (
            <div>
              <div className="formTitle">Language: {lang.toUpperCase()}</div>
            </div>
          ) : null}

          <div>
            <div className="formTitle">
              Title{" "}
              {errors.title && title === "" ? (
                <span className="error">{errors.title}</span>
              ) : null}
            </div>
            <div>
              <input
                type="text"
                placeholder="Article title"
                value={title}
                spellCheck={false}
                onChange={(e) => setTitle(e.target.value)}
                className={classnames(
                  "",
                  errors.title && title === "" ? "error" : ""
                )}
              />
            </div>
          </div>
          <div>
            <div className="formTitle">
              Short description{" "}
              {errors.shortDescription && shortDescription === "" ? (
                <span className="error">{errors.shortDescription}</span>
              ) : null}
            </div>
            <div>
              <textarea
                rows="5"
                type="text"
                placeholder="Article short description"
                value={shortDescription}
                spellCheck={false}
                onChange={(e) => setShortDescription(e.target.value)}
                className={classnames(
                  "",
                  errors.shortDescription && shortDescription === ""
                    ? "error"
                    : ""
                )}
              ></textarea>
            </div>
          </div>
          <div>
            <div className="formTitle">
              Article body{" "}
              {errors.articleBody && articleBody === "" ? (
                <span className="error">{errors.articleBody}</span>
              ) : null}
            </div>
            <div
              className={classnames(
                "editor",
                errors.articleBody && articleBody === "" ? "error" : ""
              )}
            >
              <Editor
                init={{
                  images_upload_url:
                    "http://backend.euroemlekpenz.hu/upload-image.php",
                  images_upload_handler: async function(
                    blobInfo,
                    success,
                    failure
                  ) {
                    const req = await axios
                      .post("/eurosouvenir/articles/uploadImage", {
                        img: blobInfo.base64(),
                      })
                      .catch((err) => {
                        failure(err);
                      });
                    success(req.data);
                  },
                  content_style: `@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap"); html {height: calc(100% - 40px);} body {height: 100%; font-family: "Montserrat", sans-serif !important;}`,
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist textcolor autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | fontsizeselect | image | bold italic forecolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent",
                }}
                onEditorChange={handleEditorChange}
                value={articleBody}
              />
            </div>
          </div>
          <div>
            <div className="formTitle">
              Article image{" "}
              {errors.articleImg && articleImg === "" ? (
                <span className="error">{errors.articleImg}</span>
              ) : null}
            </div>
            {articleImg === "" ? (
              <div
                className={classnames(
                  "uploadMainBanknote seeMoreImageUpload",
                  errors.articleImg && articleImg === "" ? "error" : ""
                )}
              >
                {articleImg === "" ? (
                  <label htmlFor="articleImg" className="imgUploadLabel">
                    <AddIcon />
                  </label>
                ) : null}
              </div>
            ) : (
              <div className="mainBankNoteImgWrapper">
                <div className="deleteImg" onClick={deleteArticleImg}>
                  <CloseIcon />
                </div>
                <img src={articleImg} className="mainBanknote" alt="" />
              </div>
            )}
            <input
              type="file"
              id="articleImg"
              disabled={articleImg === "" ? false : true}
              name="articleImg"
              accept="image/png, image/jpeg"
              style={{ display: "none" }}
              onChange={handleArticleImg}
              onClick={(e) => {
                e.target.value = "";
              }}
            />
          </div>
          <div>
            <div className="submitNew article">
              <div className="uploadingNewPoint" id="uploadingNewArticle">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <button
                onClick={(e) => submitNewArticle(e)}
                disabled={submitted === true ? true : false}
              >
                {Object.keys(editingArticle).length > 0 &&
                editingArticle.constructor === Object
                  ? "Update article"
                  : "Add new article"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Articles(props) {
  const [articles, setArticles] = useState([]);

  const [newArticleVisible, setNewArticleVisible] = useState(false);
  const [getNewArticles, setGetNewArticles] = useState(true);
  const [resetHandler, setResetHandler] = useState(true);

  const [editArticle, setEditArticle] = useState("");
  const [lang, setLang] = useState("");

  useEffect(() => {
    setLang(props.lang);
  }, [props.lang]);

  useEffect(() => {
    if (lang !== "") getArticles();
  }, [getNewArticles, props.newArticles, lang]);

  const getArticles = async () => {
    const req = await axios.get("/eurosouvenir/articles/get", {
      params: {
        lang,
      },
    });
    setArticles(req.data);
  };

  const addNewArticle = () => {
    setNewArticleVisible(true);
  };

  const newArticleAdded = () => {
    setGetNewArticles(!getNewArticles);
    handleCloseArticles();
  };

  const handleCloseArticles = () => {
    setEditArticle("");
    setResetHandler(!resetHandler);
    setNewArticleVisible(false);
    props.closeArticles();
  };

  const editArticleHandler = (id) => {
    setEditArticle(id);
    setNewArticleVisible(true);
  };

  const closeNewArticle = () => {
    setEditArticle("");
    setNewArticleVisible(false);
    setResetHandler(!resetHandler);
  };

  return (
    <div className="articlesAdmin">
      <div className="closeAdmin fixed" onClick={handleCloseArticles}>
        <CloseIcon />
      </div>

      <div
        className={classnames(
          "newArticleWrapper",
          newArticleVisible ? "" : "hidden"
        )}
      >
        <Scrollbar type="newArticle">
          <NewArticle
            editArticle={editArticle}
            close={newArticleAdded}
            closeNewArticle={closeNewArticle}
            reset={resetHandler}
            deleteArticle={props.deleteArticle}
            lang={props.lang}
          />
        </Scrollbar>
      </div>

      <div
        className={classnames(
          "articlesAdminInsideWrapper",
          newArticleVisible ? "hidden" : ""
        )}
      >
        <Scrollbar type="articlesAdmin">
          <div className="adminArticlesList">
            {articles.map((article, index) => (
              <div className="singleArticleAdmin" key={article._id}>
                <div className="deleteArticle">
                  <button
                    title="Delete article"
                    onClick={() => props.deleteArticle(article._id)}
                  >
                    Delete article
                  </button>
                  <button
                    title="Edit article"
                    onClick={() => editArticleHandler(article._id)}
                    className="editArticle"
                  >
                    Edit article
                  </button>
                </div>
                <div className="singleArticleContainer">
                  <div>
                    <div>
                      <div className="articleTitle">
                        {article.title ? article.title[lang] : null}
                      </div>
                      <div className="articleShortDescription">
                        {article.shortDescription
                          ? article.shortDescription[lang]
                          : null}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="articleImgContainer">
                        <img src={article.articleImgURL} alt="" />
                      </div>
                      <div className="articleSeeButton">
                        <Link to={`/article/${article._id}`}>
                          <button className="seeArticle">Show article</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="newArticle" onClick={addNewArticle}>
              <div>
                <div>
                  <AddIcon />
                </div>
                <div className="newArticleText">New article</div>
              </div>
            </div>
          </div>
        </Scrollbar>
      </div>
    </div>
  );
}

function NewPointInfo(props) {
  const [newPoint, setNewPoint] = useState({});
  const [available, setAvailable] = useState(true);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [mainDesc, setMainDesc] = useState("");
  const [secDesc, setSecDesc] = useState("");
  const [mainBanknote, setMainBanknote] = useState("");
  const [seeMoreImg, setSeeMoreImg] = useState("");
  const [moreBanknotes, setMoreBanknotes] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [infoVideo, setInfoVideo] = useState({});

  const [editPoint, setEditPoint] = useState({});

  const [errors, setErrors] = useState({});

  const [lang, setLang] = useState("");

  useEffect(() => {
    setLang(props.lang);
  }, [props.lang]);

  useEffect(() => {
    setNewPoint(props.newPoint);
    setAvailable(true);
    setTitle("");
    setSubtitle("");
    setMainDesc("");
    setSecDesc("");
    setMainBanknote("");
    setSeeMoreImg("");
    setMoreBanknotes([]);
    setErrors({});
    setSubmitted(false);
    setInfoVideo({ url: "", blob: "" });
    props.submitted(false);
    scrollToTop();
    setEditPoint({});
  }, [props.newPoint]);

  useEffect(() => {
    if (props.editPoint !== "") {
      setSubmitted(false);
      props.submitted(false);
      getPoint(props.editPoint);
    } else {
      setEditPoint({});
    }
  }, [props.editPoint]);

  const getPoint = async (id) => {
    const req = await axios.get("/eurosouvenir/points/info", {
      params: {
        _id: id,
        lang,
      },
    });
    setEditPoint(req.data);
    setNewPoint(req.data.newPoint);
    setAvailable(req.data.available);
    setTitle(req.data.title[lang]);
    setSubtitle(req.data.subtitle[lang]);
    setMainDesc(req.data.mainDesc[lang]);
    setSecDesc(req.data.secDesc[lang]);
    setMainBanknote(req.data.mainBanknoteURL);
    setMoreBanknotes(
      req.data.moreBanknotesURLs.map((banknote, index) => {
        return { url: banknote, index: index };
      })
    );
    setSeeMoreImg(req.data.seeMoreImgURL);
    setInfoVideo({ url: req.data.infoVideoURL, blob: "" });
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        "PNG",
        1280,
        940,
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const handleMainBanknote = async (e) => {
    const image = await resizeFile(e.target.files[0]);
    setMainBanknote(image);
  };

  const handleSeeMoreImage = async (e) => {
    const image = await resizeFile(e.target.files[0]);
    setSeeMoreImg(image);
  };

  const handleInfoVideo = async (e) => {
    const blob = new Blob([e.target.files[0]], { type: "video/mp4" });
    const videoURL = window.URL.createObjectURL(blob);
    setInfoVideo({ url: e.target.files[0], blob: videoURL });
  };

  const handleMoreBanknotes = async (e) => {
    const image = await resizeFile(e.target.files[0]);
    const banknoteObject = {
      url: image,
      index: moreBanknotes.length,
    };
    setMoreBanknotes([...moreBanknotes, banknoteObject]);
  };

  const scrollToTop = () => {
    animateScroll.scrollToTop({
      containerId: "newPointInfoScroll",
      duration: 0,
      delay: 0,
      smooth: false,
    });
  };

  const submitNewPoint = async (e) => {
    e.preventDefault();
    const newPointObject = {
      newPoint,
      title,
      subtitle,
      mainDesc,
      secDesc,
      mainBanknote,
      seeMoreImg,
      moreBanknotes,
      infoVideo: infoVideo.url,
    };

    const validInput = validateInput(newPointObject);

    if (validInput.isValid === true) {
      setSubmitted(true);
      props.submitted(true);
      document.getElementById("uploadingNewPoint").classList.add("visible");

      let form = new FormData();
      if (editPoint._id) form.append("editPoint", JSON.stringify(editPoint));

      if (infoVideo.url !== editPoint.infoVideoURL)
        form.append("infoVideo", infoVideo.url, {
          contentType: "video/mp4",
        });
      else form.append("infoVideo", infoVideo.url);

      if (editPoint._id) form.append("_id", editPoint._id);
      form.append("available", available);
      form.append("newPoint", JSON.stringify(newPoint));
      form.append("title", title);
      form.append("subtitle", subtitle);
      form.append("mainDesc", mainDesc);
      form.append("secDesc", secDesc);
      form.append("mainBanknote", mainBanknote);
      form.append("moreBanknotes", JSON.stringify(moreBanknotes));
      form.append("seeMoreImg", seeMoreImg);
      form.append("lang", lang);

      if (
        Object.keys(editPoint).length > 0 &&
        editPoint.constructor === Object
      ) {
        axios
          .post("/eurosouvenir/points/edit", form, { headers: form.getHeaders })
          .then(() => {
            setTimeout(() => {
              document
                .getElementById("uploadingNewPoint")
                .classList.remove("visible");
              props.close();
            }, 500);
          });
      } else {
        axios
          .post("/eurosouvenir/points/new", form, { headers: form.getHeaders })
          .then(() => {
            document
              .getElementById("uploadingNewPoint")
              .classList.remove("visible");
            props.close();
          });
      }
    } else {
      setErrors(validInput.errors);
    }
  };

  const deleteSeeMoreImg = () => {
    setSeeMoreImg("");
  };

  const deleteInfoVideo = () => {
    setInfoVideo({ url: "", blob: "" });
  };

  const deleteFromMoreBanknotes = (ImgIndex) => {
    setMoreBanknotes(
      moreBanknotes.filter((banknote, index) => {
        if (index !== ImgIndex) return banknote;
      })
    );
  };

  const deleteMainBanknote = () => {
    setMainBanknote("");
  };

  return (
    <div className="newPointInfoFormWrapper">
      {props.editPoint !== "" ? (
        <div>
          <div className="formTitle">Language: {lang.toUpperCase()}</div>
        </div>
      ) : null}
      <div>
        {errors.newPoint ? (
          <div style={{ fontSize: "1.25em" }}>
            <b>
              <span className="error nomargin">! {errors.newPoint}</span>
            </b>
          </div>
        ) : null}
        <div className="formTitle availableCheckbox">
          <input
            type="checkbox"
            checked={available ? true : false}
            onChange={() => {
              setAvailable(!available);
            }}
          />{" "}
          Available
        </div>
      </div>
      <div>
        <div className="formTitle">
          Title{" "}
          {errors.title && title === "" ? (
            <span className="error">{errors.title}</span>
          ) : null}
        </div>
        <div>
          <input
            type="text"
            placeholder="e.g. Komárom"
            value={title}
            spellCheck={false}
            onChange={(e) => setTitle(e.target.value)}
            className={classnames(
              "",
              errors.title && title === "" ? "error" : ""
            )}
          />
        </div>
      </div>
      <div>
        <div className="formTitle">
          Subtitle{" "}
          {errors.subtitle && subtitle === "" ? (
            <span className="error">{errors.subtitle}</span>
          ) : null}
        </div>
        <div>
          <input
            type="text"
            placeholder="e.g. MONOSTORI EROD"
            value={subtitle}
            spellCheck={false}
            onChange={(e) => setSubtitle(e.target.value)}
            className={classnames(
              "",
              errors.subtitle && subtitle === "" ? "error" : ""
            )}
          />
        </div>
      </div>
      <div>
        <div className="formTitle">
          Main description{" "}
          {errors.mainDesc && mainDesc === "" ? (
            <span className="error">{errors.mainDesc}</span>
          ) : null}
        </div>
        <div>
        <Editor
            init={{
              images_upload_url:
                "http://backend.euroemlekpenz.hu/upload-image.php",
              images_upload_handler: async function(
                blobInfo,
                success,
                failure
              ) {
                const req = await axios
                  .post("/eurosouvenir/articles/uploadImage", {
                    img: blobInfo.base64(),
                  })
                  .catch((err) => {
                    failure(err);
                  });
                success(req.data);
              },
              content_style: `@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap"); html {height: calc(100% - 40px);} body {height: 100%; font-family: "Montserrat", sans-serif !important;}`,
              height: 500,
              menubar: false,
              plugins: [
                "advlist textcolor autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | fontsizeselect | image | bold italic forecolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent",
            }}
            onEditorChange={(e) => setMainDesc(e)}
            value={mainDesc}
          />
        </div>
      </div>
      <div>
        <div className="formTitle">
          Secondary description{" "}
          {errors.secDesc && secDesc === "" ? (
            <span className="error">{errors.secDesc}</span>
          ) : null}
        </div>
        <div>
          <Editor
            init={{
              images_upload_url:
                "http://backend.euroemlekpenz.hu/upload-image.php",
              images_upload_handler: async function(
                blobInfo,
                success,
                failure
              ) {
                const req = await axios
                  .post("/eurosouvenir/articles/uploadImage", {
                    img: blobInfo.base64(),
                  })
                  .catch((err) => {
                    failure(err);
                  });
                success(req.data);
              },
              content_style: `@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap"); html {height: calc(100% - 40px);} body {height: 100%; font-family: "Montserrat", sans-serif !important;}`,
              height: 500,
              menubar: false,
              plugins: [
                "advlist textcolor autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | fontsizeselect | image | bold italic forecolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent",
            }}
            onEditorChange={(e) => setSecDesc(e)}
            value={secDesc}
          />
        </div>
      </div>

      <div>
        <div className="formTitle">
          Main banknote image{" "}
          {errors.mainBanknote && mainBanknote === "" ? (
            <span className="error">{errors.mainBanknote}</span>
          ) : null}
        </div>
        {mainBanknote === "" ? (
          <div
            className={classnames(
              "uploadMainBanknote",
              errors.mainBanknote && mainBanknote === "" ? "error" : ""
            )}
          >
            {mainBanknote === "" ? (
              <label htmlFor="mainBanknote" className="imgUploadLabel">
                <AddIcon />
              </label>
            ) : null}
          </div>
        ) : (
          <div className="mainBankNoteImgWrapper">
            <div className="deleteImg" onClick={deleteMainBanknote}>
              <CloseIcon />
            </div>
            <img src={mainBanknote} className="mainBanknote" alt="" />
          </div>
        )}
        <input
          type="file"
          id="mainBanknote"
          disabled={mainBanknote === "" ? false : true}
          name="mainBanknote"
          accept="image/png, image/jpeg"
          style={{ display: "none" }}
          onChange={handleMainBanknote}
          onClick={(e) => {
            e.target.value = "";
          }}
        />
      </div>
      <div>
        <div className="formTitle">
          More banknote images (e.g. backside) <br />{" "}
          {errors.moreBanknotes && moreBanknotes.length === 0 ? (
            <span className="error nomargin">{errors.moreBanknotes}</span>
          ) : null}
        </div>
        <div className="moreBanknotesWrapper">
          {moreBanknotes.map((banknote, index) => (
            <div className="moreBanknotesSingleWrapper" key={banknote.url}>
              <div
                className="deleteImg"
                onClick={() => {
                  deleteFromMoreBanknotes(index);
                }}
              >
                <CloseIcon />
              </div>
              <img
                key={index}
                className="moreBanknotesSingle"
                src={banknote.url}
                alt=""
              />
            </div>
          ))}
          <div
            className={classnames(
              "uploadMoreBanknotes",
              errors.moreBanknotes && moreBanknotes.length === 0 ? "error" : ""
            )}
          >
            <label htmlFor="moreBanknotes" className="imgUploadLabel">
              <AddIcon />
            </label>
          </div>
        </div>
        <input
          type="file"
          id="moreBanknotes"
          name="moreBanknotes"
          accept="image/png, image/jpeg"
          style={{ display: "none" }}
          onChange={handleMoreBanknotes}
          onClick={(e) => {
            e.target.value = "";
          }}
        />
      </div>
      <div>
        <div className="formTitle">
          See more image{" "}
          {errors.seeMoreImg && seeMoreImg === "" ? (
            <span className="error">{errors.seeMoreImg}</span>
          ) : null}
        </div>
        {seeMoreImg === "" ? (
          <div
            className={classnames(
              "uploadMainBanknote seeMoreImageUpload",
              errors.seeMoreImg && seeMoreImg === "" ? "error" : ""
            )}
          >
            {seeMoreImg === "" ? (
              <label htmlFor="seeMoreImg" className="imgUploadLabel">
                <AddIcon />
              </label>
            ) : null}
          </div>
        ) : (
          <div className="mainBankNoteImgWrapper">
            <div className="deleteImg" onClick={deleteSeeMoreImg}>
              <CloseIcon />
            </div>
            <img src={seeMoreImg} className="mainBanknote" alt="" />
          </div>
        )}
        <input
          type="file"
          id="seeMoreImg"
          disabled={seeMoreImg === "" ? false : true}
          name="seeMoreImg"
          accept="image/png, image/jpeg"
          style={{ display: "none" }}
          onChange={handleSeeMoreImage}
          onClick={(e) => {
            e.target.value = "";
          }}
        />
      </div>
      <div>
        <div className="formTitle">
          Video{" "}
          {errors.infoVideo && infoVideo.url === "" ? (
            <span className="error">{errors.infoVideo}</span>
          ) : null}
        </div>
        {infoVideo.url === "" ? (
          <div
            className={classnames(
              "uploadMainBanknote seeMoreImageUpload",
              errors.infoVideo && infoVideo.url === "" ? "error" : ""
            )}
          >
            <label htmlFor="infoVideo" className="imgUploadLabel">
              <AddIcon />
            </label>
          </div>
        ) : (
          <div className="mainBankNoteImgWrapper infoVideoWrapper">
            <div className="deleteImg" onClick={deleteInfoVideo}>
              <CloseIcon />
            </div>
            <div className="videoPlayerAdmin">
              <VideoPlayer
                url={infoVideo.blob === "" ? infoVideo.url : infoVideo.blob}
              />
            </div>
          </div>
        )}
        <input
          type="file"
          id="infoVideo"
          disabled={infoVideo.url === "" ? false : true}
          name="infoVideo"
          accept="video/mp4, video/x-m4v, video/*"
          style={{ display: "none" }}
          onChange={handleInfoVideo}
          onClick={(e) => {
            e.target.value = "";
          }}
        />
      </div>
      <div>
        <div className="submitNew newPoint">
          <div className="uploadingNewPoint" id="uploadingNewPoint">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <button
            onClick={(e) => submitNewPoint(e)}
            disabled={submitted === true ? true : false}
          >
            {Object.keys(editPoint).length > 0 &&
            editPoint.constructor === Object
              ? "Update map point"
              : "Add new point"}
          </button>
        </div>
      </div>
    </div>
  );
}

function Admin(props) {
  const [addPointCursor, setAddPointCursor] = useState(false);
  const [newPoints, setNewPoints] = useState([]);

  const [newPointInfoVisible, setNewPointInfoVisible] = useState(false);
  const [articlesVisible, setArticlesVisible] = useState(false);
  const [newArticles, setNewArticles] = useState(true);

  const [submitted, setSubmitted] = useState(false);

  const [getPoints, setGetPoints] = useState(false);

  const [editPointID, setEditPointID] = useState("");

  const [newPoint, setNewPoint] = useState({});

  const [lang, setLang] = useState("");

  useEffect(() => {
    setLang(props.lang);
  }, [props.lang]);

  const submitHandler = (state) => {
    setSubmitted(state);
  };

  const addPoint = () => {
    setAddPointCursor(!addPointCursor);
  };

  const closeWithNewPoint = () => {
    setNewPointInfoVisible(false);
    setEditPointID("");
    setGetPoints(!getPoints);
    setNewPoints([]);
  };

  const closeNewPointInfo = () => {
    if (submitted !== true) {
      setNewPointInfoVisible(false);
      setEditPointID("");
      setNewPoints(
        newPoints.filter((point, index) => {
          return index !== newPoints.length - 1;
        })
      );
      setTimeout(function() {
        setNewPoint({});
      }, 350);
    }
  };

  const closeArticles = () => {
    setArticlesVisible(false);
  };

  const showArticles = () => {
    setArticlesVisible(true);
  };

  const pointChosenHandler = (position) => {
    setAddPointCursor(false);
    const newPointChosen = (
      <Point top={position.top + "%"} left={position.left + "%"} />
    );
    setNewPoint(newPointChosen);
    setNewPoints([...newPoints, newPointChosen]);
    setTimeout(function() {
      setNewPointInfoVisible(true);
    }, 500);
  };

  const deleteArticle = async (id) => {
    if (window.confirm("Are you sure you want to delete this article?")) {
      await props.deleteArticle(props.auth.user.id, id);
      setNewArticles(!newArticles);
    }
  };

  const editPoint = (id) => {
    setEditPointID(id);
    setNewPointInfoVisible(true);
  };

  return (
    <div className="content">
      <div className="content--inner">
        <div className="adminWrapper">
          <div
            className={classnames(
              "newPointInfoWrapper",
              newPointInfoVisible ? "" : "hidden"
            )}
          >
            <div className="newPointInfo">
              <div className="closeAdmin" onClick={closeNewPointInfo}>
                <CloseIcon />
              </div>
              <Scrollbar type="newPoint">
                <NewPointInfo
                  newPoint={newPoint.props}
                  close={closeWithNewPoint}
                  submitted={submitHandler}
                  editPoint={editPointID}
                  lang={lang}
                />
              </Scrollbar>
            </div>
          </div>
          <div
            className={classnames(
              "articlesAdminWrapper",
              articlesVisible ? "" : "hidden"
            )}
          >
            <Articles
              newArticles={newArticles}
              deleteArticle={deleteArticle}
              closeArticles={closeArticles}
              lang={lang}
            />
          </div>
          <AdminMap
            cursor={addPointCursor}
            pointChosenHandler={pointChosenHandler}
            newPoints={newPoints}
            getPoints={getPoints}
            editPoint={editPoint}
            lang={lang}
          >
            <div className="adminHeader">
              <div className="addPointWrapper">
                <button
                  onClick={addPoint}
                  className={classnames(
                    "addPoint",
                    addPointCursor === true ? "active" : ""
                  )}
                >
                  Add point
                </button>{" "}
                {addPointCursor === true ? (
                  <div className="setYourPointText">
                    Set your point on the map
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="adminArticlesButton">
                <button
                  onClick={showArticles}
                  className={classnames(
                    "addPoint",
                    articlesVisible === true ? "active" : ""
                  )}
                >
                  Show articles
                </button>
              </div>
            </div>
          </AdminMap>
        </div>
      </div>
    </div>
  );
}

Admin.propTypes = {
  deleteArticle: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { logoutUser, deleteArticle })(
  withRouter(Admin)
);
