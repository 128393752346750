import React, { useState, useEffect } from "react";
import "./Map.css";
import axios from "../../axios";

import classnames from "classnames";

import { Transition } from "react-transition-group";

import Language from "../Language/Language";
import Point from "../Point/Point";
import languageData from '../variables/language.json';

function Map(props) {
  const [activeInfo, setActiveInfo] = useState(0);
  const [points, setPoints] = useState([]);

  const [lang, setLang] = useState("");

  useEffect(() => {
    setLang(props.lang);
  }, [props.lang]);

  useEffect(() => {
    if(lang !== "") {
      document.title = "EuroSouvenir | " + languageData.map[lang];
      getPoints();
    }
  }, [props.getPoints, lang]);

  useEffect(() => {
    if (points.length > 0) {
      setTimeout(() => {
        if (document.getElementById("availableInfo"))
          document.getElementById("availableInfo").classList.add("swipeInLeft");
        if (document.getElementById("hungaryEntryAnimation"))
          document
            .getElementById("hungaryEntryAnimation")
            .classList.add("show");
      }, 500);
    }
  }, [points]);

  const onClickHandler = (e) => {
    if (props.onClick && props.cursor === true) {
      props.onClick(e);
    }
  };

  const getPoints = async () => {
    const req = await axios.get("/eurosouvenir/points/get", {
      params: {
        lang,
      },
    });
    setPoints(req.data);
  };

  const pointClicked = (id) => {
    if (id === activeInfo) {
      setActiveInfo(0);
    } else {
      setActiveInfo(id);
    }
  };

  return (
    <div className="content">
      <div className="content--inner">
        <div className="itemContainer mapContainer">
          {props.children}
          <Language contact={true} lang={lang} openContact={props.openContact}/>
          <div className="titleWrapper map">
            <h2>
              <span className="hiddenOpacity">
                <span className="titleDark">Euro</span>Souvenir{" "}
              </span>
              <span className="titleLine">|</span>{" "}
              <span
                className="titleSmall hungaryEntryAnimation"
                id="hungaryEntryAnimation"
              >
                <Transition timeout={50}>
                  <span>
                    <span>H</span>
                    <span>u</span>
                    <span>n</span>
                    <span>g</span>
                    <span>a</span>
                    <span>r</span>
                    <span>y</span>
                  </span>
                </Transition>
              </span>
            </h2>
          </div>
          <div id="hungaryMap" className="hungaryMapContainer">
            <div
              onClick={(e) => onClickHandler(e)}
              className={classnames(
                "hungaryMapWrapper",
                props.cursor === true ? "crosshair" : ""
              )}
            >
              {props.newPoints
                ? props.newPoints.map((point) => {
                    return point;
                  })
                : null}
              {points.map((point, index) => (
                <Point
                  lang={lang}
                  pointClicked={() => pointClicked(point._id)}
                  id={point._id}
                  key={point._id}
                  visible={activeInfo === point._id ? true : false}
                  available={point.available}
                  top={point.newPoint.top}
                  left={point.newPoint.left}
                  admin={props.admin === true ? true : false}
                  points={points}
                  getPoints={getPoints}
                  editPoint={props.editPoint}
                />
              ))}
            </div>
          </div>
          <div id="availableInfo" className="availableInfoWrapper">
            <div className="availableInfo">
              <div className="mapLegend">{languageData.mapLegend[lang]}</div>
              <div className="availableIcon">
                <Point icon={true} available={true} />{" "}
                <div className="mapLegendText">{languageData.mapLegendAvailable[lang]}</div>
              </div>
              <div className="comingSoonIcon">
                <Point icon={true} />{" "}
                <div className="mapLegendText">{languageData.mapLegendComingSoon[lang]}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Map;
