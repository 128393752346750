import React, { useState, useEffect } from "react";
import "./Sidebar.css";

import { Link } from "react-router-dom";

import classnames from "classnames";

import EuroSouvenirLogo from "../../imgs/euro_0e_ikona.png";
import Map from "../../imgs/euro_souvenir_ikonka_map.png";
import News from "../../imgs/euro_souvenir_ikonka_news.png";
import Catalogue from "../../imgs/euro_souvenir_ikonka_catalogue.png";

import AppStore from "../../imgs/download_appstore.png";
import GooglePlay from "../../imgs/download_googleplay.png";

import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";

import { logoutUser } from "../actions/authActions";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import languageData from "../variables/language.json";

function Sidebar(props) {
  const [lang, setLang] = useState("");

  const [activeMobile, setActiveMobile] = useState(false);

  useEffect(() => {
    setLang(props.lang);
  }, [props.lang]);

  return (
    <div className="sidebar">
      <div className="sidebarContainerMobile">
        <div
          className={classnames(
            "burgerMenu",
            activeMobile === true ? "active" : ""
          )}
          onClick={() => setActiveMobile(!activeMobile)}
        >
          {activeMobile === true ? <MenuOpenIcon /> : <MenuIcon />}
        </div>
        <div
          className={classnames(
            "fixedMenu",
            activeMobile === true ? "active" : ""
          )}
        >
          <div className="fixedMenuInside">
            <Link to={`/`} onClick={() => setActiveMobile(false)}>
              <div className="icon logo">
                <img src={EuroSouvenirLogo} alt="Eurosouvenir logo" />
              </div>
            </Link>
            <Link to={`/`} onClick={() => setActiveMobile(false)}>
              <div className="icon mapIcon">
                <div>
                  <img src={Map} alt="Eurosouvenir logo" />
                </div>
                <div>{languageData.map[lang]}</div>
              </div>
            </Link>
            <Link to={`/news`} onClick={() => setActiveMobile(false)}>
              <div className="icon newsIcon">
                <div>
                  <img src={News} alt="Eurosouvenir logo" />
                </div>
                <div>{languageData.news[lang]}</div>
              </div>
            </Link>
            <Link to={`/catalogue`} onClick={() => setActiveMobile(false)}>
              <div className="icon catalogueIcon">
                <div>
                  <img src={Catalogue} alt="Eurosouvenir logo" />
                </div>
                <div>{languageData.catalogue[lang]}</div>
              </div>
            </Link>
            {props.auth.isAuthenticated === true ? (
              <Link to={`/my-catalogue`} onClick={() => setActiveMobile(false)}>
                <div className="icon catalogueIcon">
                  <div>
                    <img src={Catalogue} alt="Eurosouvenir logo" />
                  </div>
                  <div>{languageData.myCatalogue[lang]}</div>
                </div>
              </Link>
            ) : null}
            <div className="bottomSidebar">
              {props.auth.isAuthenticated === true ? (
                <div className="authSidebar">
                  <button onClick={() => {props.logoutUser(); setActiveMobile(false)}}>
                    {languageData.logout[lang]}
                  </button>
                </div>
              ) : (
                <div className="authSidebar">
                  <Link to="/login" onClick={() => setActiveMobile(false)}>
                    <button>{languageData.signIn[lang]}</button>
                  </Link>
                </div>
              )}
              <a href="https://apps.apple.com/ph/app/eurosouvenir/id1507969347">
                <div className="icon download">
                  <div className="bottomLogoWrapper">
                    <img src={AppStore} alt="Appstore" />
                  </div>
                </div>
              </a>
              <a
                href={`https://play.google.com/store/apps/details?id=sk.eurosouvenir&hl=cs&gl=US`}
              >
                <div className="icon download">
                  <div className="bottomLogoWrapper">
                    <img src={GooglePlay} alt="Google play" />
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="sidebarContainer">
        <div className="iconsWrapper">
          <Link to={`/`}>
            <div className="icon logo">
              <img src={EuroSouvenirLogo} alt="Eurosouvenir logo" />
            </div>
          </Link>
          <Link to={`/`}>
            <div className="icon mapIcon">
              <div>
                <img src={Map} alt="Eurosouvenir logo" />
              </div>
              <div>{languageData.map[lang]}</div>
            </div>
          </Link>
          <Link to={`/news`}>
            <div className="icon newsIcon">
              <div>
                <img src={News} alt="Eurosouvenir logo" />
              </div>
              <div>{languageData.news[lang]}</div>
            </div>
          </Link>
          <Link to={`/catalogue`}>
            <div className="icon catalogueIcon">
              <div>
                <img src={Catalogue} alt="Eurosouvenir logo" />
              </div>
              <div>{languageData.catalogue[lang]}</div>
            </div>
          </Link>
          {props.auth.isAuthenticated === true ? (
            <Link to={`/my-catalogue`}>
              <div className="icon catalogueIcon">
                <div>
                  <img src={Catalogue} alt="Eurosouvenir logo" />
                </div>
                <div>{languageData.myCatalogue[lang]}</div>
              </div>
            </Link>
          ) : null}
        </div>
        <div className="bottomSidebar">
          {props.auth.isAuthenticated === true ? (
            <div className="authSidebar">
              <button onClick={() => props.logoutUser()}>
                {languageData.logout[lang]}
              </button>
            </div>
          ) : (
            <div className="authSidebar">
              <Link to="/login">
                <button>{languageData.signIn[lang]}</button>
              </Link>
            </div>
          )}

          <div className="icon bottomLogo">
            <div className="bottomLogoWrapper">
              <img src={EuroSouvenirLogo} alt="Eurosouvenir logo" />
            </div>
            <div>EuroSouvenir</div>
          </div>
          <a href="https://apps.apple.com/ph/app/eurosouvenir/id1507969347">
            <div className="icon download">
              <div className="bottomLogoWrapper">
                <img src={AppStore} alt="Appstore" />
              </div>
            </div>
          </a>
          <a
            href={`https://play.google.com/store/apps/details?id=sk.eurosouvenir&hl=cs&gl=US`}
          >
            <div className="icon download">
              <div className="bottomLogoWrapper">
                <img src={GooglePlay} alt="Google play" />
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { logoutUser })(withRouter(Sidebar));
