import { TimelineMax as Timeline, gsap, Power1 } from "gsap";
import { CSSPlugin } from 'gsap/CSSPlugin';

gsap.registerPlugin(CSSPlugin)

const getDefaultTimeline = (node, delay) => {
  const timeline = new Timeline({ paused: true });
  let contentInner;
  if(node !== null)
    contentInner = node.querySelector(".content--inner")
    

  if (contentInner) {
    timeline
      .from(node, 0.3, {
        display: "none",
        autoAlpha: 0,
        delay,
        ease: Power1.easeIn,
      })
      .from(contentInner, 0.15, {
        autoAlpha: 0,
        delay: 0.15,
        ease: Power1.easeIn,
      });
  }

  return timeline;
};

export const play = (pathname, node, appears) => {
  const delay = appears ? 0 : 0.5;
  let timeline;
  timeline = getDefaultTimeline(node, delay);

  timeline.play();
};

export const exit = (node) => {
  const timeline = new Timeline({ paused: true });

  timeline.to(node, 0.3, { autoAlpha: 0, ease: Power1.easeOut });
  timeline.play();
};
