import React, { useEffect, useState } from "react";
import "./Catalogue.css";

import { CSSTransition } from "react-transition-group";

import { Link } from "react-router-dom";
import axios from "../../axios";

import Language from "../Language/Language";

import languageData from '../variables/language.json';

function Catalogue(props) {
  const [points, setPoints] = useState([]);
  const [title, setTitle] = useState([]);
  const [lang, setLang] = useState("");

  useEffect(() => {
    setLang(props.lang);
  }, [props.lang])

  useEffect(() => {
    if(lang !== "") {
      document.title = "EuroSouvenir | " + languageData.catalogue[lang];
      getPoints();
    }
  }, [lang]);

  const getPoints = async () => {
    const req = await axios.get("/eurosouvenir/points/get", {
      params: {
        lang
      }
    });
    setPoints(req.data);

    let catalogue = languageData.catalogue[lang].toUpperCase();
    let arrayOfTitle = [];
      for (let i = 0; i < catalogue.length; i++) {
        if (i === 0)
          arrayOfTitle.push(
            <span key={i} style={{ transitionDelay: "350ms" }}>
              {catalogue[i]}
            </span>
          );
        else
          arrayOfTitle.push(
            <span key={i} style={{ transitionDelay: (i + 2) * 175 + "ms" }}>
              {catalogue[i]}
            </span>
          );
      }
      setTitle(arrayOfTitle);
  };

  useEffect(() => {
    if (points.length > 0) {
      setTimeout(() => {
        if (document.getElementById("catalogueEntryAnimation"))
          document
            .getElementById("catalogueEntryAnimation")
            .classList.add("show");
        [].forEach.call(
          document.querySelectorAll(".cataloguePointSingle"),
          (el) => {
            el.classList.add("visible");
          }
        );
      }, 500);
    }
  }, [points]);

  return (
    <div className="content">
      <div className="content--inner">
        <div className="itemContainer mapContainer">
        <Language contact={false} />
          <div className="titleWrapper catalogue">
            <h2>
              <span className="hiddenOpacity">
                <span className="titleDark">Euro</span>Souvenir{" "}
              </span>
              <span className="titleLine">|</span>{" "}
              <span
                className="titleSmall hungaryEntryAnimation"
                id="catalogueEntryAnimation"
              >
                <CSSTransition timeout={500} in={points.length > 0 ? true : false} unmountOnExit={true} classNames="show">
                  <span>
                    {title}
                  </span>
                </CSSTransition>
              </span>
            </h2>
          </div>
          <div className="catalogueWrapper">
            <div className="catalogueContainer">
              {points.map((point, index) => (
                <div
                  key={point._id}
                  className="cataloguePointSingle"
                  style={{ transitionDelay: (index + 1) * 275 + "ms" }}
                >
                  <div className="cataloguePointInside">
                    <div className="cataloguePointImg">
                      <img src={point.mainBanknoteURL} alt="" />
                    </div>
                    <div className="seeMoreLine">
                      <div>
                        {point.title ? point.title[lang] : null} <br />
                        {point.subtitle ? point.subtitle[lang] : null}
                      </div>
                      {point.available ? (
                        <Link to={`/map/${point._id}`}>
                          <div className="seeMoreButton">{languageData.mapSeeMore[lang]}</div>
                        </Link>
                      ) : (
                        <div className="seeMoreButton">{languageData.mapComingSoon[lang]}</div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Catalogue;
