import React, { useState } from 'react';

import { Scrollbars } from 'react-custom-scrollbars';

function Scrollbar(props) {

    const [top, setTop] = useState({top: 0});
    const [type, setType] = useState(props.type);

    function handleScrollFrame(values) {
        const { top } = values;
        setTop({top})
    }

    function renderTrack({ style, ...props }) {
        let right = 2;
        let offsetVertical = 2;

        if(type === "newPoint" || type === "newArticle") {
            right = 60
            offsetVertical = 20
        }
        if(type === "articlesAdmin") {
            right = 30;
            offsetVertical = 20;
        }

        const trackStyle = {
            position: "absolute",
            width: 6,
            right: right,
            bottom: offsetVertical,
            top: offsetVertical,
            borderRadius: 3
        };
        return (
            <div
                style={{ ...style, ...trackStyle }}
                {...props}/>
        );
    }

    function renderView({ style, ...props }) {
        const color = top * 255;
        const customStyle = {
            backgroundColor: `rgb(${color}, ${color}, ${color})`,
            overflowX: "hidden",
            marginBottom: 0,
            padding: "0 20px",
            paddingRight: 30,
            paddingLeft: 0
        };

        let id = ""

        if(type === "newPoint") {
            id = "newPointInfoScroll";
        }
        else if(type === "articlesAdmin") {
            id= "articlesAdminScroll"
        }
        
        
        return (
            <div id={id} {...props} style={{ ...style, ...customStyle }}/>
        );
    }

    return(
            <Scrollbars
                renderView={renderView}
                onScrollFrame={handleScrollFrame}
                renderTrackVertical={renderTrack}
                {...props}/>
    )
}

export default Scrollbar;