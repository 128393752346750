import React from 'react'
import './HungaryLine.css';

function HungaryLine() {
    return (
        <div className="hungaryLine">
            <div className="hungaryRed"></div>
            <div className="hungaryWhite"></div>
            <div className="hungaryGreen"></div>
        </div>
    )
}

export default HungaryLine
