import React, { useState, useEffect } from "react";

import "./MoneyImages.css";

import VideoPlayer from '../VideoPlayer/VideoPlayer'

import classnames from "classnames";

import CloseIcon from "@material-ui/icons/Close";

function MoneyImages(props) {
  const [mainImage, setMainImage] = useState("");
  const [hoverImage, setHoverImage] = useState("");
  const [banknotes, setBanknotes] = useState([]);

  const [lightbox, setLightbox] = useState(false);
  
  useEffect(() => {
    if (props.moreBanknotes && props.mainBanknote) {
      setBanknotes(
        [].concat.apply(
          [],
          [
            <img
              key={"00"}
              src={props.mainBanknote}
              alt=""
              onClick={() => setMainImageHandler(props.mainBanknote)}
              onMouseOver={() => mouseOverHandler(props.mainBanknote)}
              onMouseOut={mouseOutHandler}
            />,
            props.moreBanknotes.map((banknote, index) => {
              return (
                <img
                  key={index}
                  src={banknote}
                  alt=""
                  onClick={() => setMainImageHandler(banknote)}
                  onMouseOver={() => mouseOverHandler(banknote)}
                  onMouseOut={mouseOutHandler}
                />
              );
            }),
          ]
        )
      );
    }
  }, [props.mainBanknote, props.moreBanknotes]);

  const setMainImageHandler = (src) => {
    setMainImage(src);
  };

  const mouseOverHandler = (src) => {
    setHoverImage(src);
  };

  const mouseOutHandler = () => {
    setHoverImage("");
  };

  const lightboxTriggerHandler = () => {
    setLightbox(!lightbox);
  };

  return (
    <div className="moneyImages">
      <div className="mainImgWrapper">
        <img
          src={
            hoverImage === ""
              ? mainImage === ""
                ? props.mainBanknote
                : mainImage
              : hoverImage
          }
          alt=""
          onClick={lightboxTriggerHandler}
          title="Open gallery"
        />{" "}
      </div>
      <div className="imgSelectionWrapper">{banknotes}</div>

      <div className="banknoteVideo">
        <VideoPlayer url={props.infoVideo} />
      </div>

      <div
        className={classnames("lightbox", lightbox === true ? "active" : "")}
      >
        <div className="insideLightboxContainer">
          <div
            className="closeLightbox"
            title="Close"
            onClick={lightboxTriggerHandler}
          >
            <CloseIcon />
          </div>
          <div className="mainImage">
            <img
              src={
                hoverImage === ""
                  ? mainImage === ""
                    ? props.mainBanknote
                    : mainImage
                  : hoverImage
              }
              alt=""
            />
          </div>
          <div className="otherImages">{banknotes}</div>
        </div>
      </div>
    </div>
  );
}

export default MoneyImages;
