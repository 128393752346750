import React, {useState, useEffect} from "react";
import ReactPlayer from "react-player";
import "./VideoPlayer.css";
import classnames from 'classnames';

import PlayArrowIcon from '@material-ui/icons/PlayArrow';

function VideoPlayer(props) {

    const [playing, setPlaying] = useState(false);

    useEffect(() => {
        setPlaying(false);
    }, [props.url])

    const playHandler = () => {
        setPlaying(!playing);
    }

  return (
    <div>
      <ReactPlayer
        playing={playing}
        onPause={playHandler}
        onStart={playHandler}
        {...props}
        controls={true}
        volume={0}
        
        style={{ width: "auto", maxWidth: "100%" }}
        config={{
            file: {
                attributes: {
                    "controlsList": "nodownload noremoteplayback",
                    "disablePictureInPicture" : true
                }
            }
        }}
      />
      <div className={classnames("playButtonOverlap", playing ? "hidden" : "")}>
        <div className="playButtonContainer" onClick={playHandler}>
          <div className="playArrowWrapper">
            <PlayArrowIcon />
          </div>
          <div className="playButtonVideoText">VIDEO</div>
        </div>
      </div>
    </div>
  );
}

export default VideoPlayer;
