import React, { useEffect, useState } from "react";
import "./MyCatalogue.css";

import { CSSTransition } from "react-transition-group";

import { Link } from "react-router-dom";
import axios from "../../axios";

import DeleteIcon from '@material-ui/icons/Delete';

import Language from "../Language/Language";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import languageData from '../variables/language.json';

function MyCatalogue(props) {
  const [catalogue, setCatalogue] = useState({});
  const [catalogueTitle, setCatalogueTitle] = useState([]);
  const [newTitle, setNewTitle] = useState({ old: "", new: "" });
  const [banknotes, setBanknotes] = useState([]);

  const [lang, setLang] = useState("");

  useEffect(() => {
    if(props.auth.isAuthenticated === false) 
      props.history.push("/");
  }, [props.auth])

  useEffect(() => {
    setLang(props.lang);
  }, [props.lang])

  useEffect(() => {
    if(lang !== "") {
      document.title = "EuroSouvenir | " + languageData.myCatalogue[lang];
      getCatalogue();
    }
  }, [lang]);

  const getCatalogue = async () => {
    const req = await axios.get("/eurosouvenir/users/catalogue", {
      params: {
        _id: props.auth.user.id,
        lang
      },
    });
    setCatalogue(req.data);
    setBanknotes(req.data.banknotes);
    setTimeout(() => {
      [].forEach.call(
        document.querySelectorAll(".cataloguePointSingle"),
        (el) => {
          el.classList.add("visible");
        }
      );
    }, 500);
  };

  useEffect(() => {
    if (catalogue.name) {
      setNewTitle({ old: catalogue.name, new: catalogue.name });
      setCatalogueTitle(catalogueTitleHandler(catalogue.name));
    }
  }, [catalogue]);

  const confirmNewTitle = async (e) => {
    if (newTitle.new !== newTitle.old) {
      if (newTitle.new.length > 0 && newTitle.new.length < 24) {
        await axios.post("/eurosouvenir/users/catalogue/name", {
          _id: props.auth.user.id,
          name: newTitle.new,
        });
        e.target.blur();
        setNewTitle({ old: newTitle.new, new: newTitle.new });
        setCatalogueTitle(catalogueTitleHandler(newTitle.new));
      }
    }
  };
  
  const removeBanknoteHandler = async(id) => {
    await axios.post("/eurosouvenir/users/catalogue/remove", {
      _id: props.auth.user.id,
      banknote: id,
    });
    setTimeout(() => {
      getCatalogue();
    }, 250)
  }

  const catalogueTitleHandler = (title) => {
    let arrayOfTitle = [];
    for (let i = 0; i < title.length; i++) {
      if (i === 0)
        arrayOfTitle.push(
          <span key={i} style={{ transitionDelay: "350ms" }}>
            {title[i]}
          </span>
        );
      else
        arrayOfTitle.push(
          <span key={i} style={{ transitionDelay: (i + 2) * 175 + "ms" }}>
            {title[i]}
          </span>
        );
    }
    return arrayOfTitle;
  };

  return (
    <div className="content">
      <div className="content--inner">
        <div className="itemContainer myCatalogue">
          <Language contact={false} />
          <div className="titleWrapper myCatalogue">
            <h2>
              <span className="hiddenOpacity">
                <span className="titleDark">Euro</span>Souvenir{" "}
              </span>
              <span className="titleLine">|</span>{" "}
              <span
                className="titleSmall hungaryEntryAnimation"
                id="mycatalogueEntryAnimation"
              >
                <CSSTransition
                  timeout={500}
                  in={catalogue.name ? true : false}
                  unmountOnExit={true}
                  classNames="show"
                >
                  <span>
                    <div className="inputMyCatalogue">
                      <input
                        spellCheck={false}
                        type="text"
                        value={newTitle.new}
                        onBlur={(e) =>
                          setNewTitle({ old: newTitle.old, new: newTitle.old })
                        }
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            confirmNewTitle(e);
                          }
                        }}
                        onChange={(e) =>
                          setNewTitle({
                            old: newTitle.old,
                            new: e.target.value,
                          })
                        }
                      />
                    </div>
                    {catalogueTitle}
                  </span>
                </CSSTransition>
              </span>
            </h2>
          </div>

          <div className="myCatalogueWrapper">
            {catalogue.banknotes ? (
              catalogue.banknotes.length === 0 ? (
                <div className="emptyWrapper">
                  <div className="emptyContainer">
                    <div className="emptyTextUp">Your catalogue is empty</div>
                    <div className="emptyTextDown">
                      To add banknotes, visit{" "}
                      <span className="linkToMap">
                        <Link to="/">Map.</Link>
                      </span>
                    </div>
                  </div>
                </div>
              ) : banknotes ? (
                banknotes.length > 0 ? (
                  <div className="catalogueWrapper">
                    <div className="catalogueContainer">
                      {banknotes.map((banknote, index) => (
                        <div
                          key={banknote._id}
                          className="cataloguePointSingle"
                          style={{ transitionDelay: (index + 1) * 275 + "ms" }}
                        >
                          <div className="cataloguePointInside">
                            <div className="removeBanknote" title={languageData.banknoteRemove[lang]}>
                              <button onClick={() => removeBanknoteHandler(banknote._id)}><DeleteIcon /></button>
                            </div>

                            <div className="cataloguePointImg">
                              <img src={banknote.mainBanknoteURL} alt="" />
                            </div>
                            <div className="seeMoreLine">
                              <div>
                                {banknote.title ? banknote.title[lang] : null} <br />
                                {banknote.subtitle ? banknote.subtitle[lang] : null}
                              </div>
                              {banknote.available ? (
                                <Link to={`/map/${banknote._id}`}>
                                  <div className="seeMoreButton">{languageData.mapSeeMore[lang]}</div>
                                </Link>
                              ) : (
                                <div className="seeMoreButton">{languageData.mapComingSoon[lang]}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null
              ) : null
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

MyCatalogue.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps)(withRouter(MyCatalogue));
