import Validator from 'validator';
import isEmpty from 'is-empty';

export const validateArticleInput = (data) => {
    let errors = {};

    if(Validator.isEmpty(data.title)) {
        errors.title = "Title is empty"
    }
    if(Validator.isEmpty(data.shortDescription)) {
        errors.shortDescription = "Short description is empty"
    }
    if(Validator.isEmpty(data.articleBody)) {
        errors.articleBody = "Body is empty"
    }
    if(Validator.isEmpty(data.articleImg)) {
        errors.articleImg = "Article image is empty"
    }

    return {
        errors,
        isValid: isEmpty(errors)
    };
}

export const validateInput = (data) => {

    let errors = {};

    if(isEmpty(data.newPoint)) {
        errors.newPoint = "New point is not selected"
    }
    if(Validator.isEmpty(data.title)) {
        errors.title = "Title is empty"
    }
    if(Validator.isEmpty(data.subtitle)) {
        errors.subtitle = "Subtitle is empty"
    }
    if(Validator.isEmpty(data.mainDesc)) {
        errors.mainDesc = "Main description is empty"
    }
    if(Validator.isEmpty(data.secDesc)) {
        errors.secDesc = "Second description is empty"
    }
    if(Validator.isEmpty(data.mainBanknote)) {
        errors.mainBanknote = "Must contain image"
    }
    if(Validator.isEmpty(data.seeMoreImg)) {
        errors.seeMoreImg = "Must contain image"
    }

    if(data.infoVideo === "") {
        errors.infoVideo = "Must contain video"
    }
    if(data.moreBanknotes.length === 0) {
        errors.moreBanknotes = "Must contain atleast one image"
    }

    return {
        errors,
        isValid: isEmpty(errors)
    };

}