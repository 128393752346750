import React, { useEffect, useState } from "react";
import "./PointInfo.css";

import { Link } from "react-router-dom";
import axios from "../../axios";

import classnames from "classnames";

import Scrollbar from "../Scrollbar/Scrollbar";

import CloseIcon from "@material-ui/icons/Close";

import languageData from "../variables/language.json";

function PointInfo(props) {
  const [pointInfo, setPointInfo] = useState({});

  useEffect(() => {
    getPointInfo();
  }, [props.id, props.reset]);

  const getPointInfo = async () => {
    const req = await axios.get("/eurosouvenir/points/info", {
      params: {
        _id: props.id,
        lang: props.lang,
      },
    });
    setPointInfo(req.data);
  };

  return (
    <div
      className={classnames(
        "pointInfoWrapper",
        parseFloat(props.left) > 50 ? "right" : "",
        props.available ? "available" : ""
      )}
    >
      {props.children}
      <div className="closeIcon" onClick={props.closeHandler}>
        <CloseIcon />
      </div>
      <div className="cashImgWrapper">
        <img src={pointInfo.mainBanknoteURL} alt="" />
      </div>
      <div className="seeMoreLineWrapper">
        <div className="seeMoreLineTip"></div>
        <div className="seeMoreLine">
          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              width: "75%",
            }}
          >
            {pointInfo.title ? pointInfo.title[props.lang] : null} <br />
            <span
              style={{
                fontSize: ".825em",
                fontWeight: "500",
              }}
            >
              {pointInfo.subtitle ? pointInfo.subtitle[props.lang] : null}
            </span>
          </div>
          {pointInfo.available ? (
            <div
              style={{
                width: 120,
                marginRight: 20,
                textAlign: "center",
              }}
            >
              <Link to={`/map/${props.id}`}>
                <div className="seeMoreButton">
                  {languageData.mapSeeMore[props.lang]}
                </div>
              </Link>
            </div>
          ) : (
            <div
              style={{
                width: 120,
                marginRight: 20,
                textAlign: "center",
              }}
            >
              <div className="seeMoreButton">
                {languageData.mapComingSoon[props.lang]}
              </div>
            </div>
          )}
        </div>
      </div>
      <Scrollbar
        className="textPointInfoScrollbar"
        style={{
          height: window.innerWidth < 960 ? 173 : 106,
          margin: 20,
          width: "auto",
        }}
      >
        <div
          className="textPointInfo"
          dangerouslySetInnerHTML={{
            __html: pointInfo.mainDesc ? pointInfo.mainDesc[props.lang] : null,
          }}
        ></div>
      </Scrollbar>
    </div>
  );
}

export default PointInfo;
