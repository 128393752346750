import React, { useState } from "react";
import "./Language.css";

import classnames from "classnames";

import flagHU from "../../imgs/flagHU.png";
import flagDE from "../../imgs/flagDE.png";
import flagEN from "../../imgs/flagEN.png";

import languageData from "../variables/language.json";

function Language(props) {
  return (
    <div className={classnames("language", props.main === true ? "main" : "")}>
      <div
        className={classnames(
          "languageContainer",
          props.main === true ? "main" : ""
        )}
      >
        <div className="flex-center-all">
          {props.contact === true ? (
            <div className="textLanguage" onClick={() => {if(props.openContact) props.openContact()}}>
              {languageData.contactText[props.lang]}
            </div>
          ) : null}
          <div
            className={classnames(
              "languageIcons",
              props.main === true ? "" : "hidden"
            )}
          >
            <div
              className={classnames(
                "languageIcon",
                props.lang === "hu" ? "active" : ""
              )}
              onClick={() => props.langHandler("hu")}
            >
              HU <img src={flagHU} alt="" />
            </div>
            <div
              className={classnames(
                "languageIcon",
                props.lang === "de" ? "active" : ""
              )}
              onClick={() => props.langHandler("de")}
            >
              DE <img src={flagDE} alt="" />
            </div>
            <div
              className={classnames(
                "languageIcon",
                props.lang === "en" ? "active" : ""
              )}
              onClick={() => props.langHandler("en")}
            >
              EN <img src={flagEN} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Language;
