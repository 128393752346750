import React from "react";
import "./AdminMap.css";

import Map from "../Map/Map";

function AdminMap(props) {
  const mapClickHandler = (e) => {
    const position = {
      top:
        Math.round(
          ((100 * (e.clientY - e.target.offsetTop + document.getElementsByTagName("body")[0].scrollTop)) / e.target.clientHeight) *
            100
        ) / 100,
      left:
        Math.round(
          ((100 *
            (e.clientX -
              (e.target.offsetParent.offsetParent.offsetParent.offsetLeft +
                e.target.offsetLeft))) /
            e.target.clientWidth) *
            100
        ) / 100,
    };
    props.pointChosenHandler(position);
  };

  return (
    <div className="adminMap">
      <Map
        editPoint={props.editPoint}
        admin={true}
        match={{ params: { id: 0 } }}
        cursor={props.cursor}
        onClick={(position) => mapClickHandler(position)}
        newPoints={props.newPoints}
        getPoints={props.getPoints}
        lang={props.lang}
      >
        {props.children}
      </Map>
    </div>
  );
}

export default AdminMap;
