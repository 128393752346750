import React from "react";
import "./Point.css";

import PointInfo from "./PointInfo";

import { deleteMapPoint } from "../actions/authActions";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import classnames from "classnames";

function Point(props) {

  const deleteMapPoint = async (user, id) => {
    if(window.confirm("Are you sure you want to delete this map point?")) {
      await props.deleteMapPoint(user, id);
      props.getPoints();
    }
  };

  const pointClickHandler = (id) => {
    if (props.pointClicked) props.pointClicked(id);
  };

  return (
    <div
      className={classnames(
        "mapPoint",
        props.available ? "available" : "",
        props.visible ? "visible active" : "",
        props.icon ? "icon" : ""
      )}
      style={{ top: props.top, left: props.left }}
    >
      <div
        className="pointContainer"
        onClick={() => pointClickHandler(props.id)}
      >
        <div className="circleInner"></div>
        <div className="circleOuter"></div>
        <div className="circleOuter2"></div>
        <div className="boxShadowPoint"></div>
      </div>
      {props.icon ? null : (
        <PointInfo
          available={props.available}
          closeHandler={() => pointClickHandler(0)}
          id={props.id}
          left={props.left}
          lang={props.lang}
          reset={props.points}
        >
          {props.admin === true && props.auth.isAdmin === true ? (
            <div className="adminHeaderPoint">
            <div
              onClick={() => deleteMapPoint(props.auth.user.id, props.id)}
              className="deletePoint"
              title="Click to delete map point"
            >
              Delete map point
            </div>
            <div
              onClick={() => props.editPoint(props.id)}
              className="editPoint"
              title="Click to edit map point"
            >
              Edit map point
            </div>
            </div>
          ) : null}
        </PointInfo>
      )}
    </div>
  );
}

Point.propTypes = {
  deleteMapPoint: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { deleteMapPoint })(withRouter(Point));
